import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useCurrentUser } from './UserContextProvider'
const Organizations = lazy(() => import('../pages/Organizations'))
const EditOrganization = lazy(() =>
  import('../pages/organizations/EditOrganization')
)
const Users = lazy(() => import('../pages/Users'))
const EditUser = lazy(() => import('../pages/users/EditUser'))
const UserGroups = lazy(() => import('../pages/UserGroups'))
const EditUserGroup = lazy(() => import('../pages/groups/EditGroup'))
const Security = lazy(() => import('../pages/Security'))
const Settings = lazy(() => import('../pages/Settings'))
const UserPartners = lazy(() => import('../pages/UserPartners'))
const NotFound = lazy(() => import('../pages/NotFound'))

const getDefaultRoute = (permissions) => {
  if (permissions.listOrg) {
    return '/pbx'
  }
  if (permissions.listUser) {
    return '/users'
  }
  if (permissions.listGroup) {
    return '/usergroups'
  }
  if (permissions.retrievePartner) {
    return '/security'
  }

  return '/'
}

export default () => {
  const { permissions } = useCurrentUser() 
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route exact path='/pbx'>
          <Organizations />
        </Route>
        <Route path='/pbx/:id'>
          <EditOrganization />
        </Route>
        <Route exact path='/users'>
          <Users />
        </Route>
        <Route path='/users/:id'>
          <EditUser />
        </Route>
        <Route exact path='/usergroups'>
          <UserGroups />
        </Route>
        <Route path='/usergroups/:id'>
          <EditUserGroup />
        </Route>
        <Route path='/security'>
          <Security />
        </Route>
        <Route path='/settings'>
          <Settings />
        </Route>
        <Route exact path='/partners'>
          <UserPartners />
        </Route>
        <Route exact path='/(default)?'>
          <Redirect id='defaultRouteRedirect' to={getDefaultRoute(permissions)} />
        </Route>
        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  )
}
