import React, { useState, useEffect, createContext } from 'react'
import { IntlProvider } from 'react-intl'
import { messages } from '../utils/i18n'
import config from '../utils/config'

export const LocaleContext = createContext()

export const getCurrentLocale = () =>
  localStorage.getItem('locale') || config().DEFAULT_LANGUAGE

export default ({ children }) => {
  const [locale, setLocale] = useState(navigator.language)
  useEffect(() => {
    const currentLocale = localStorage.getItem('locale')
    if (currentLocale && locale !== currentLocale) {
      setLocale(currentLocale)
    }
  }, [locale])
  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider
        locale={locale}
        defaultLocale={config().DEFAULT_LANGUAGE}
        messages={messages[locale]}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  )
}
