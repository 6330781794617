import React from 'react'
import { render } from 'react-dom'
import { initGoogleAnalytics } from './utils/ga'
import { initInternationalization } from './utils/i18n'
import App from './components/App'

initGoogleAnalytics()
initInternationalization()

render(<App />, document.getElementById('root'))
