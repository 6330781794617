import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import createTheme from '../utils/createTheme'

export const ThemeContext = createContext()

const ThemeProvider = ({ sizeBase, children }) => {
  const [fontSize] = useState(sizeBase)
  const theme = createTheme({ fontSize, fontLineHeight: fontSize + 8 })
  return (
    <ThemeContext.Provider value={{ fontSize }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  sizeBase: PropTypes.number.isRequired,
  children: PropTypes.object
}

export default ThemeProvider
