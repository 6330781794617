export default {
  local: {
    LOG_LEVEL: 'TRACE'
  },
  development: {
    LOG_LEVEL: 'DEBUG'
  },
  staging: {
    LOG_LEVEL: 'INFO'
  },
  production: {
    LOG_LEVEL: 'INFO'
  },
  hvoice: {
    LOG_LEVEL: 'INFO'
  }
}
