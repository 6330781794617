import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { CHAM_YELLOW } from '../../utils/styles/colors'
import makeStyles from '../../utils/makeStyles'

const useStyles = makeStyles(({ spacing }) => ({
  warning: {
    padding: spacing(1),
    backgroundColor: CHAM_YELLOW[400],
    textAlign: 'center'
  }
}))

const SupportBanner = ({ isProvidesTierOneSupport }) => {
  const classes = useStyles()
  if (!isProvidesTierOneSupport) {
    return null
  }
  return (
    <div position='static'>
      <Typography
        variant='subtitle1'
        color='inherit'
        className={classes.warning}
      >
        <FormattedMessage
          id='bannerTierOneSupport'
          defaultMessage='Partner provides Tier One Support'
        />
      </Typography>
    </div>
  )
}

SupportBanner.propTypes = {
  isProvidesTierOneSupport: PropTypes.bool.isRequired
}

export default SupportBanner
