import React from 'react'
import Grid from '@material-ui/core/Grid'
import AppBar from './commons/menus/AppBar'
import LeftMenu from './commons/menus/LeftMenu'
import Router from './Router'
import UserContextProvider from './UserContextProvider'
import makeStyles from '../utils/makeStyles'

const useStyles = makeStyles(
  ({
    breakpoints,
    spacing,
    palette: {
      common: { white, grey }
    }
  }) => ({
    leftCol: {
      backgroundColor: white.main,
      borderRight: `1px solid ${grey.light}`,
      [breakpoints.down('md')]: {
        width: 55
      }
    },
    contents: {
      padding: spacing(2),
      backgroundColor: white.dark
    }
  })
)

export default () => {
  const classes = useStyles()
  return (
    <UserContextProvider>
      <Grid container>
        <AppBar />
      </Grid>
      <Grid container>
        <Grid item lg={2} className={classes.leftCol}>
          <LeftMenu />
        </Grid>
        <Grid item xs={10} md={11} lg={10} className={classes.contents}>
          <Router />
        </Grid>
      </Grid>
    </UserContextProvider>
  )
}
