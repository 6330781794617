import ga from 'react-ga'
import config from './config'
import logger from './logger'
import * as url from './url'

export const PARAMS = Object.freeze({
  server: {
    required: ['access_token', 'type', 'expires_in', 'principal'],
    optional: ['state', 'error']
  },
  client: {
    required: ['response_type', 'client_id', 'redirect_uri', 'scope'],
    optional: ['state']
  }
})

export const ERR_MISSING_PARAMETERS = 'Required parameters are missing.'

export const getLocalAuthenticationData = () => {
  const json = localStorage.getItem(config().AUTH_DATA_STORAGE_KEY)
  if (!json) {
    return null
  }
  try {
    return JSON.parse(json)
  } catch (err) {
    logger.warn('Local auth data parsing error:', err)
  }
  return null
}

export const setLocalAuthenticationData = data =>
  localStorage.setItem(
    config().AUTH_DATA_STORAGE_KEY,
    JSON.stringify({
      ...data,
      username: data.principal,
      expires_in: +data.expires_in * 1000,
      scopes: config().AUTH_SCOPES,
      ldap: config().AUTH_LDAP,
      time: Date.now()
    })
  )

export const getSerializedAuthenticationToken = () => {
  const authData = getLocalAuthenticationData() || {}
  return authData.access_token
}

export const isExpired = ({ time, expires_in }) => {
  time = +time
  expires_in = +expires_in
  return time + expires_in < Date.now()
}

export const logout = () => {
  localStorage.removeItem(config().AUTH_DATA_STORAGE_KEY)
  ga.set({ partnerId: null })
  ga.event({
    category: 'Authentication',
    action: 'Logout'
  })
}

export const objectParamsFilter = model =>
  (() => {
    const allParams = [...model.required, ...model.optional]
    return p => allParams.includes(p)
  })()

export const isValidAuthParams = ({ model, params }) => {
  const keys = Object.keys(params)
  const intersection = model.required.filter(p => keys.includes(p))
  if (intersection.length < model.required.length) {
    return [false, ERR_MISSING_PARAMETERS]
  }
  return [true]
}

const fakeUUID = () => btoa(String(Math.random() * Math.pow(2, 10)))

const buildServerToken = ({ scope, client_id }) => {
  const token = {
    scp: scope,
    sub: client_id,
    ldap: config().AUTH_LDAP,
    iss: 'default',
    typ: 'access',
    gnt: fakeUUID(),
    exp: Date.now() + 60 * 60 * 1000,
    jti: fakeUUID(),
    cid: fakeUUID()
  }
  return [{ alg: 'HS512' }, token]
    .map(o => JSON.stringify(o))
    .map(s => btoa(s))
    .join('.')
}

export const buildServerResponseParams = clientRequest => ({
  access_token: buildServerToken(clientRequest),
  type: 'bearer',
  expires_in: 60 * 60 * 1000,
  principal: 'auburn@example.com'
})

export const buildClientRequestParams = ({ inflightRequest }) => ({
  response_type: 'token',
  client_id: config().AUTH_CLIENT_ID,
  redirect_uri: config().AUTH_CALLBACK_URL,
  scope: config().AUTH_SCOPES,
  state: inflightRequest
})

export const isSuccessCallback = location => {
  if (!location.pathname.includes('/authenticate/callback')) {
    return false
  }
  const params = url.extractUrlParameters({
    sources: [location.search, location.hash],
    filter: objectParamsFilter(PARAMS.server)
  })
  logger.debug('Authentication callback parameters:', params)
  if (!isValidAuthParams({ model: PARAMS.server, params: params })) {
    logger.warn('Invalid authentication callback parameters.', params)
    return false
  }
  if (params.error) {
    logger.warn('Authentication callback error:', params.error)
    return false
  }
  setLocalAuthenticationData(params)
  logger.debug('Authentication data saved.')
  return true
}

export const getLocalLoginRedirectUrl = location => {
  const [part2, part3] = url.splitPathname(location, 1)
  if (part2 !== 'authenticate') {
    return
  }
  // server process (auth validation + access token creation)
  if (!part3) {
    const params = url.extractUrlParameters({
      sources: [location.search],
      filter: objectParamsFilter(PARAMS.client)
    })
    params.redirect_uri = decodeURIComponent(params.redirect_uri)
    logger.debug('Authentication request parameters:', params)
    // error simulation
    if (
      !config().IS_TEST_ENV &&
      config().AUTH_LOCAL_SIMULATE_ERRORS_FREQUENCY >= Math.random()
    ) {
      logger.warn('Simulated authentication error.')
      return url.buildUrl({
        url: location.origin,
        request: { error: 'auth' },
        join: '?'
      })
    }
    if (!isValidAuthParams({ model: PARAMS.client, params: params })) {
      throw new Error('Invalid authentication request parameters.')
    }
    const redirectUrl = url.buildUrl({
      url: params.redirect_uri,
      request: buildServerResponseParams(params),
      join: '#'
    })
    logger.debug('Authentication callback redirection:', redirectUrl)
    return redirectUrl
  }

  // callback process (save auth data in the localStorage)
  if (isSuccessCallback(location)) {
    return '/'
  }
}

export const getAuthRequestUrl = ({ inflightRequest }) =>
  url.buildUrl({
    url: config().AUTH_SERVICE_URL,
    request: buildClientRequestParams({ inflightRequest }),
    join: '?'
  })

export const retrieveUserPartners = async authenticated => {
  if (config().IS_LOCAL_ENV) {
    return []
  }
  const path = window.location.pathname.split('/').filter(s => s)
  if (!authenticated || path[1] === 'authenticate') {
    return []
  }
  const { access_token } = getLocalAuthenticationData()
  const res = await fetch(config().APP_BFF_URL + '/partners', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `bearer ${access_token}`
    }
  }).then(res => {
    return res.json()
  })
  if (res.error) {
    logger.error(res.error)
  }
  return res.partners
}
