import ga from 'react-ga'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import fragmentTypes from './fragmentTypes'
import { onError } from 'apollo-link-error'
import { UUID_REGEX, isAccountKey } from './utils'
import { redirect, getUrlPartnerId } from './url'
import {
  getSerializedAuthenticationToken,
  getAuthRequestUrl,
  logout
} from './auth'
import config from './config'
import logger from './logger'

const httpLink = createHttpLink({ uri: config().APP_BFF_URL + '/graphql' })
const X_JIVE_PARTNER_BASE = 'x-jive-partner-'
const X_JIVE_PARTNER_ID = X_JIVE_PARTNER_BASE + 'id'
const X_JIVE_PARTNER_DOMAIN = X_JIVE_PARTNER_BASE + 'domain'
const X_JIVE_PARTNER_ACCOUNTKEY = X_JIVE_PARTNER_BASE + 'accountkey'

const authLink = setContext(() => {
  const partnerId = getUrlPartnerId()
  const authToken = getSerializedAuthenticationToken()

  let xJivePartnerHeader = X_JIVE_PARTNER_DOMAIN

  if (UUID_REGEX.test(partnerId)) {
    xJivePartnerHeader = X_JIVE_PARTNER_ID
  } else if (isAccountKey(partnerId)) {
    xJivePartnerHeader = X_JIVE_PARTNER_ACCOUNTKEY
  }

  return {
    headers: {
      [xJivePartnerHeader]: partnerId,
      Authorization: `bearer ${authToken}`
    }
  }
})

const retrievePartnerIdLink = new ApolloLink((operation, forward) => {
  const { headers } = operation.getContext()
  localStorage.setItem(config().PARTNER_UUID_KEY,
    headers[X_JIVE_PARTNER_ID] ||
    headers[X_JIVE_PARTNER_DOMAIN] ||
    headers[X_JIVE_PARTNER_ACCOUNTKEY]
  )
  return forward(operation)
})

const errorLink = onError(({ networkError, operation }) => {
  const query = operation.query.loc.source.body
  if (networkError) {
    logger.error(`[Network error]: ${networkError}, query: ${query}`)
    ga.exception({ description: 'GraphQL network error:' + networkError })
    const { statusCode } = networkError
    if (statusCode === 401) {
      logout()
      redirect(getAuthRequestUrl())
      throw new Error('User not yet authenticated')
    } else if (statusCode === 404) {
      const partnerId = getUrlPartnerId()
      redirect(
        `${
          config().APP_URL
        }?error=partnerNotFound&partner=${partnerId}&fixed=true`
      )
      throw new Error(`Partner not found: ${partnerId}`)
    }
  }
})

const timingLink = new ApolloLink((operation, forward) => {
  if (!operation.operationName) {
    return forward(operation)
  }
  const startedAt = new Date().getTime()
  return forward(operation).map(data => {
    const durationMs = new Date().getTime() - startedAt
    ga.timing({
      category: 'GraphQL network',
      variable: operation.operationName,
      value: durationMs
    })
    return data
  })
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes
})

export const cache = new InMemoryCache({ fragmentMatcher })

const link = errorLink
  .concat(authLink)
  .concat(timingLink)
  .concat(retrievePartnerIdLink)
  .concat(httpLink)

export const apolloClient = new ApolloClient({ link, cache })
