import gql from 'graphql-tag'

export const PING_QUERY = gql`
  query {
    pong: ping {
      env
      appVersion
    }
  }
`

export const PARTNER_QUERY = gql`
  query {
    partner {
      name
      isProvidesTierOneSupport
    }
  }
`

export const GET_LANGS_QUERY = gql`
  query Languages($currentLocale: String!) {
    languages(currentLocale: $currentLocale) {
      name
      code
      localName
      region
    }
  }
`
