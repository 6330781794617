import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import ga from 'react-ga'
import MuiButton from '@material-ui/core/Button'
import MuiIconButton from '@material-ui/core/IconButton'

const ButtonWithEventTracking = Comp =>
  forwardRef(({ analytics, ...props }, ref) => {
    const onClick = target => {
      if (!props.onClick) {
        return
      }
      props.onClick(target)
      ga.event({
        category: 'Navigation',
        action: 'Click',
        ...analytics
      })
    }
    return (
      <Comp {...props} ref={ref} onClick={onClick}>
        {props.children}
      </Comp>
    )
  })

const ButtonWithEventTrackingPropTypes = {
  analytics: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string.isRequired
  }).isRequired
}

export const Button = ButtonWithEventTracking(MuiButton)
Button.propTypes = ButtonWithEventTrackingPropTypes

export const IconButton = ButtonWithEventTracking(MuiIconButton)
IconButton.propTypes = ButtonWithEventTrackingPropTypes
