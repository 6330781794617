import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '../../utils/makeStyles'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    paddingTop: spacing(1)
  }
}))

export default ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography color='textSecondary'>
        {children || (
          <FormattedMessage id='loading' defaultMessage='Loading...' />
        )}
      </Typography>
      <CircularProgress color='primary' {...props} />
    </div>
  )
}
