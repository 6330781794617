import React, { useState, useEffect } from 'react'
import ga from 'react-ga'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import buildUrl from 'build-url'
import config from '../utils/config'
import * as url from '../utils/url'

export const redirectToErrorPage = ({ error, fixed, partner }) => {
  const queryParams = { error }
  if (fixed) {
    queryParams.fixed = true
  }
  if (partner) {
    queryParams.partner = partner
  }
  const errorUrl = buildUrl(config().APP_URL, { queryParams })
  url.redirect(errorUrl)
}

export default ({ children }) => {
  const { error, fixed, partner } = url.extractUrlQueryParameters()
  if (!error) {
    return children
  }
  const [counter, setCounter] = useState(fixed ? Infinity : 5)
  if (!counter) {
    url.redirect(buildUrl(config().APP_URL, { path: 'home' }))
    return null
  }
  useEffect(() => {
    const ctx = setTimeout(() => setCounter(counter - 1), 1000)
    return () => clearTimeout(ctx)
  })
  return (
    <Grid style={{ margin: '5%' }} container>
      <Grid container>
        <Typography variant='subtitle1'>Error</Typography>
      </Grid>
      <Grid container>
        <Typography variant='body1'>
          {(() => {
            switch (error) {
              case 'auth':
                ga.exception({
                  description: 'ErrorHandler: Authentication error.'
                })
                return (
                  <FormattedMessage
                    id='authenticationErr'
                    defaultMessage='Authentication error.'
                  />
                )
              case 'urlPartnerId':
                ga.exception({
                  description:
                    'ErrorHandler: Invalid URL: Partner ID required.'
                })
                return (
                  <FormattedMessage
                    id='invalidUrlPartnerIdReq'
                    defaultMessage='Invalid URL: Partner ID required.'
                  />
                )
              case 'partnerNotFound':
                ga.exception({
                  description: 'ErrorHandler: Partner not found.'
                })
                return (
                  <FormattedMessage
                    id='partnerNotFound'
                    defaultMessage='Partner not found: {partner}.'
                    values={{ partner: partner || '<unknown>' }}
                  />
                )
              case 'currentUser':
                ga.exception({
                  description: 'ErrorHandler: Erorr fetching user profile.'
                })
                return (
                  <FormattedMessage
                    id='currentUserFetchingError'
                    defaultMessage='Error fetching user profile'
                  />
                )
              case 'notEnabled':
                ga.exception({
                  description: 'ErrorHandler: Partner user is not enabled.'
                })
                return (
                  <FormattedMessage
                    id='notEnabled'
                    defaultMessage='Partner user {partner} is not enabled.'
                    values={{ partner: partner || '<unknown>' }}
                  />
                )
              case 'noPartnerPerm':
                ga.exception({
                  description: 'ErrorHandler: No permission to manage partner.'
                })
                return (
                  <FormattedMessage
                    id='noPartnerPerm'
                    defaultMessage='No permission to manage partner {partner}'
                    values={{ partner: partner || '<unknown>' }}
                  />
                )
              default:
                ga.exception({ description: 'ErrorHandler: Unknown error.' })
                return (
                  <FormattedMessage
                    id='uknErr'
                    defaultMessage='Unknown error.'
                  />
                )
            }
          })()}
        </Typography>
      </Grid>
      {counter < Infinity && (
        <Grid
          id='automatic-redirect-countdown'
          container
          style={{ marginTop: 100 }}
        >
          <Typography variant='caption'>
            Automatic redirect in {counter}s
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
