import React, { createContext, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { CURRENT_USER } from '../queries/currentUser'
import { redirectToErrorPage } from './ErrorHandler'
import { getUrlPartnerId } from '../utils/url'
import { logout } from '../utils/auth'
import logger from '../utils/logger'

export const UserContext = createContext()

export const useCurrentUser = () => useContext(UserContext)

export const getPartnerAccessError = currentUser => {
  if (!currentUser.enabled) {
    return 'notEnabled'
  }
  const {
    listOrg,
    listUser,
    listGroup,
    listDoc,
    retrievePartner
  } = currentUser.permissions
  if (!listOrg && !listUser && !listGroup && !retrievePartner && !listDoc) {
    return 'noPartnerPerm'
  }
  return null
}

export default ({ children }) => {
  const { loading, data, error } = useQuery(CURRENT_USER)
  if (loading) {
    return null
  }
  if (error || (data.result && data.result.exception)) {
    logger.error(error || data.result.exception)
    redirectToErrorPage({ error: 'currentUser', fixed: true })
    return null
  }
  const { currentUser } = data.result
  const accessError = getPartnerAccessError(currentUser)
  if (accessError) {
    logout()
    redirectToErrorPage({
      error: accessError,
      partner: getUrlPartnerId(),
      fixed: true
    })
    return null
  }

  return (
    <UserContext.Provider value={{ ...currentUser }}>
      {children}
    </UserContext.Provider>
  )
}
