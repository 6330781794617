export const ACTIVATED_LANGUAGES = [
  'en-AU',
  'en-IE',
  'en-GB',
  'en-US',
  'fr-CA',
  'fr-FR',
  'de-DE',
  'pt-BR',
  'es-MX',
  'es-ES'
]

const loadPluralRules = () => require('intl-pluralrules')

const loadRelativeTimeFormat = () => {
  require('@formatjs/intl-relativetimeformat/polyfill')
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en')
  require('@formatjs/intl-relativetimeformat/dist/locale-data/fr')
  require('@formatjs/intl-relativetimeformat/dist/locale-data/de')
  require('@formatjs/intl-relativetimeformat/dist/locale-data/pt')
  require('@formatjs/intl-relativetimeformat/dist/locale-data/es')
}

export const initInternationalization = ({
  onPluralRulesNotLoaded = loadPluralRules,
  onRelativeTimeFormatNotLoaded = loadRelativeTimeFormat
} = {}) => {
  Intl.PluralRules || onPluralRulesNotLoaded()
  Intl.RelativeTimeFormat || onRelativeTimeFormatNotLoaded()
}

export const messages = ACTIVATED_LANGUAGES.reduce(
  (acc, lang) => ({ ...acc, [lang]: require(`../translations/${lang}.json`) }),
  {}
)
