import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import ga from 'react-ga'
import { useIntl } from 'react-intl'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'

const LocaleMenuItem = forwardRef(({ id, label, locale, onSelect }, ref) => {
  const intl = useIntl()
  const selected = intl.locale === locale
  const onClick = () => {
    if (selected) {
      return
    }
    ga.event({
      category: 'Language',
      action: 'Click',
      label: `Change language to ${locale}`
    })
    onSelect(locale)
  }
  return (
    <MenuItem id={id} ref={ref} onClick={onClick} selected={selected}>
      <ListItemText primary={label} />
    </MenuItem>
  )
})

LocaleMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  ref: PropTypes.string
}

export default LocaleMenuItem
