import { IntercomAPI } from 'react-intercom'

export const DELETABLE_CLASSNAME = 'deletable-active'

export const UUID_REGEX = new RegExp(
  `^[a-f0-9]{8}(\\-[a-f0-9]{4}){3}\\-[a-f0-9]{12}$`,
  'i'
)

export const isIE = () => window.navigator.userAgent.includes('MSIE')

export const collectIdObjs = arr => arr.map(({ id }) => ({ id }))

export const removeRowAfter = deleteEntity => async ({ target }) => {
  const tr = target.closest('tr')
  tr.classList.add(DELETABLE_CLASSNAME)
  const [confirmed, cbk] = await deleteEntity()
  if (!confirmed) {
    tr.classList.remove(DELETABLE_CLASSNAME)
  }
  if (cbk) {
    cbk()
  }
}

export const shutdownSupportChat = () => IntercomAPI('shutdown')

const EMAIL_VALIDATION_PATTERN = new RegExp(
  '^([a-zA-Z0-9_.+!~\'-]+)@([a-zA-Z0-9_.-]+)\\.([a-zA-Z][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])$'
)
export const validateEmail = (email) => EMAIL_VALIDATION_PATTERN.test(email)

const ONLY_DIGITS_REGEX = new RegExp('^[0-9]*$')

export const isAccountKey = (partnerId) => ONLY_DIGITS_REGEX.test(partnerId)
