import ga from 'react-ga'
import config from './config'

export const initGoogleAnalytics = () => {
  const trackingId = config().GA_TRACKING_ID
  ga.initialize(trackingId, {
    debug: config().GA_DEBUG,
    testMode: config().GA_TEST_MODE
  })
}
