export default {
  feature: {
    APP_URL: document.baseURI,
    APP_URL_PREFIX: '',
    APP_BFF_URL: 'https://api.dev.jive.com/partner-admin',
    DEFAULT_PARTNER_ID: null,
    IS_LOCAL_ENV: false,

    AUTH_SERVICE_URL: 'https://authenticationed1.col.test.expertcity.com/oauth/authorize',
    AUTH_CALLBACK_URL: 'https://feature.partner-portal.ed.gtc.goto.com/authenticate/callback',
    AUTH_CLIENT_ID: '377b33ef-b4cb-44b7-80f3-112068c66c8f',
    AUTH_LOGOUT_URL: 'https://authenticationed1.lmiinc.test.expertcity.com/oauth/logout',
    AUTH_TOCKEN_VALIDITY_TIMEOUT: 3000,
  }
}
