import React from 'react'
import PropTypes from 'prop-types'

const RelativeImage = ({ src, alt, ...props }) => (
  <img src={`images/${src}`} alt={alt} {...props} />
)

RelativeImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
}

export default RelativeImage
