export default {
  local: {
    GA_DEBUG: false,
    GA_TEST_MODE: true,
    GA_TRACKING_ID: 'UA-123456-0'
  },
  development: {
    GA_TEST_MODE: false,
    GA_TRACKING_ID: 'UA-126153964-11'
  },
  staging: {
    GA_TEST_MODE: false,
    GA_TRACKING_ID: 'UA-126153964-12'
  },
  production: {
    GA_TEST_MODE: false,
    GA_TRACKING_ID: 'UA-126153964-11'
  },
  hvoice: {
    GA_TEST_MODE: false,
    GA_TRACKING_ID: 'UA-126153964-11'
  }
}
