import React from 'react'
import { FormattedMessage } from 'react-intl'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { useQuery } from '@apollo/react-hooks'
import { Button, IconButton } from '../Button'
import { AuthContext } from '../../Authenticator'
import LocalesMenu from './LocalesMenu'
import RelativeImage from '../RelativeImage'
import SupportBanner from '../SupportBanner'
import makeStyles from '../../../utils/makeStyles'
import { getLocalAuthenticationData } from '../../../utils/auth'
import { PARTNER_QUERY } from '../../../queries/others'
import { CHAM_BLUE } from '../../../utils/styles/colors'
import { redirect } from '../../../utils/url'
import config from '../../../utils/config'
import logger from '../../../utils/logger'

const useStyles = makeStyles(({ palette: { common: { white } } }) => ({
  logo: {
    width: 130,
    marginLeft: -20,
  },
  partnerName: {
    flexGrow: 1,
    textAlign: 'center'
  },
  sizeBtn: {
    color: white.main
  },
  logoutBtn: {
    color: white.main
  },
  loggedInUserLink: {
    fontWeight: 'inherit',
    '&:focus': {
      backgroundColor: CHAM_BLUE[600]
    }
  }
}))

export const LoggedInUserLink = () => {
  const classes = useStyles()
  return (
    <Tooltip
      title={
        <FormattedMessage
          id='loggedInUserLink'
          defaultMessage='Go to <em>{link}</em> (new window)'
          values={{
            link: config().AUTH_LOGGED_IN_USER_LINK_URL,
            em: link => <em>{link}</em>
          }}
        />
      }
    >
      <Button
        color='inherit'
        className={classes.loggedInUserLink}
        onClick={() => redirect(config().AUTH_LOGGED_IN_USER_LINK_URL)}
        analytics={{ label: 'Go to onjive (user profile)' }}
        target='_blank'
      >
        {getLocalAuthenticationData().username}
      </Button>
    </Tooltip>
  )
}

export const LogoutButton = ({ logout }) => {
  const classes = useStyles()
  return (
    <Tooltip
      title={<FormattedMessage id='logoutButton' defaultMessage='Log out' />}
    >
      <IconButton
        id='logout-btn'
        onClick={logout}
        analytics={{ label: 'Logout' }}
      >
        <CloseIcon className={classes.logoutBtn} />
      </IconButton>
    </Tooltip>
  )
}

export const AppHeader = ({ partner = {} }) => {
  const classes = useStyles()
  const { logout } = React.useContext(AuthContext)
  return (
    <AppBar id='appHeader' position='static'>
      <Toolbar>
        <RelativeImage
          src='lmi-logo.svg'
          className={classes.logo}
          alt='LogMeIn Logo'
        />
        <Typography variant='h6' component='div' color='inherit'>
          <FormattedMessage id='appTitle' defaultMessage='Partner Admin' />
        </Typography>
        <Typography
          variant='h6'
          component='div'
          color='inherit'
          className={classes.partnerName}
        >
          {partner.name}
        </Typography>
        <LocalesMenu />
        <LoggedInUserLink />
        <LogoutButton logout={logout} />
      </Toolbar>
      <SupportBanner {...partner} />
    </AppBar>
  )
}

export default () => {
  const { loading, error, data = {} } = useQuery(PARTNER_QUERY)
  if (loading) {
    return null
  }
  if (error) {
    logger.warn('Partner info Query error:', error)
  }
  return <AppHeader partner={data.partner} />
}
