export default {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'SearchableEntity',
        possibleTypes: [
          {
            name: 'Organization'
          },
          {
            name: 'Group'
          },
          {
            name: 'User'
          }
        ]
      }
    ]
  }
}