export const WHITE = '#FFFFFF'

export const CHAM_NEUTRAL = {
  100: '#F0F2F5',
  200: '#E0E4EA',
  300: '#9AA8BB',
  400: '#6B7582',
  500: '#4C535D',
  600: '#25282D'
}

export const CHAM_RED = {
  100: '#FEE2E8',
  200: '#FABCC8',
  300: '#FD94A9',
  400: '#FD4C6F',
  500: '#E02D52',
  600: '#A12E45'
}

export const CHAM_GREEN = {
  100: '#D9FFF2',
  200: '#80FFD2',
  300: '#00CC85',
  400: '#00AA6E',
  500: '#008858',
  600: '#166549'
}

export const CHAM_BLUE = {
  100: '#D9E9FF',
  200: '#BFDAFF',
  300: '#80B5FF',
  400: '#4090FF',
  500: '#006AFF',
  600: '#004FBD'
}

export const CHAM_YELLOW = {
  100: '#FFF7D9',
  200: '#FFE88C',
  300: '#FFCB00',
  400: '#D9AD00',
  500: '#B38F00',
  600: '#997A00'
}

export const CHAM_PURPLE = {
  100: '#E6D9FF',
  200: '#D5BFFF',
  300: '#AC80FF',
  400: '#9B66FF',
  500: '#7B44E4',
  600: '#6739BF'
}

export default {
  WHITE,
  NEUTRAL: CHAM_NEUTRAL,
  RED: CHAM_RED,
  GREEN: CHAM_GREEN,
  BLUE: CHAM_BLUE,
  YELLOW: CHAM_YELLOW,
  PURPLE: CHAM_PURPLE
}
