import toUrl from 'build-url'
import config from './config'
import { UUID_REGEX, isIE } from './utils'
import logger from './logger'

const PARTNER_ID_URL_REGEX = new RegExp(
  `^\\/?(${UUID_REGEX}|[\\w\\-]+)(/(${[
    'default',
    'home',
    'organizations',
    'users',
    'usergroups',
    'security',
    'settings'
  ].join('|')}))?`,
  'i'
)

const PARTNER_EXCHANGE_LANGS_MAP = {
  en: 'English',
  fr: 'French_Canada',
  es: 'Spanish',
  pt: 'Portuguese_Brazil',
  de: 'German'
}

export const getUrlPartnerId = (
  pathname = window.location.pathname,
  prefix = config().APP_URL_PREFIX
) => {
  const trimPathname = pathname.replace(prefix, '')
  return !trimPathname || trimPathname === '/'
    ? null
    : (trimPathname.match(PARTNER_ID_URL_REGEX) || [])[1]
}

export const redirect = url => {
  logger.info(`Redirecting from ${window.location.toString()} to ${url}`)
  window.location.assign(url)
}

export const checkUrlForRedirection = (userPartners, defaultPartnerId) => {
  if (!userPartners.length) {
    const redirectParams = {}
    if (defaultPartnerId) {
      logger.info(`Using [config default] partner Id: ${defaultPartnerId}`)
      redirectParams.path = `${defaultPartnerId}/default`
    } else {
      logger.warn('No partner Id fallback found.')
      redirectParams.queryParams = { error: 'urlPartnerId', fixed: true }
    }
    return toUrl(config().APP_URL, redirectParams)
  }
  logger.info(
    `No [local] partner Id, check user partners (${userPartners.length})...`
  )
  const path = [
    userPartners[0].domain,
    userPartners.length === 1 ? 'default' : 'partners'
  ]
  return toUrl(config().APP_URL, { path: path.join('/') })
}

export const baseUrl = (location = window.location) =>
  [location.protocol, '//', location.host, location.pathname].join('')

// TODO remove when IE supports URLSearchParams...
const extractUrlQueryParametersIEVersion = search =>
  search
    .substr(1)
    .replace(/&amp;/g, '&')
    .split('&')
    .map(frag => frag.split('='))
    .reduce(
      (acc, [k, v]) => (k ? { ...acc, [k]: decodeURIComponent(v) } : acc),
      {}
    )

export const extractUrlQueryParameters = (search = window.location.search) => {
  if (isIE()) {
    return extractUrlQueryParametersIEVersion(search)
  }
  const params = {}
  // eslint-disable-next-line
  for (const [key, value] of new URLSearchParams(search.substr(1))) {
    params[key] = value
  }
  return params
}

export const filterParams = ({ params, filter = p => p }) =>
  Object.keys(params)
    .filter(filter)
    .reduce((acc, k) => ({ ...acc, [k]: params[k] }), {})

export const extractUrlParameters = ({ sources, filter }) =>
  sources
    .map(source => extractUrlQueryParameters(source))
    .map(params => filterParams({ params, filter }))
    .reduce((acc, obj) => ({ ...acc, ...obj }), {})

export const updateQueryParameter = (
  k,
  value,
  search = window.location.search
) => {
  const parameters = extractUrlQueryParameters(search)
  return { ...parameters, [k]: value }
}

export const requestToUrlQuery = request =>
  Object.keys(request)
    .reduce((acc, k) => {
      const [encKey, encValue] = [k, request[k]].map(s => encodeURIComponent(s))
      return [...acc, `${encKey}=${encValue}`]
    }, [])
    .join('&')

export const buildUrl = ({ url, request, join = '?' }) =>
  [url, requestToUrlQuery(request)].join(join)

export const splitPathname = (location, skip = 0) =>
  location.pathname
    .substr(1)
    .split('/')
    .slice(skip)

export const updateUrlParameter = name => value => {
  const parameters = updateQueryParameter(name, value)
  const newUrl = buildUrl({ url: baseUrl(), request: parameters })
  window.history.pushState(null, null, newUrl)
}

export const getPartnerExchangeUrl = locale =>
  toUrl(config().PARTNER_EXCHANGE_BASE_URL, {
    path: PARTNER_EXCHANGE_LANGS_MAP[locale.substr(0, 2)] || ''
  })

export const startingSlash = path =>
  path.startsWith('/') ? path : '/'.concat(path)

export const endingSlash = path =>
  path.endsWith('/') ? path : path.concat('/')

export const normalizePath = path => startingSlash(endingSlash(path))

export const parentPath = path =>
  normalizePath(
    path
      .split('/')
      .filter(s => s)
      .slice(0, -1)
      .join('/')
  )

export const concatPaths = (...paths) =>
  normalizePath(
    paths
      .reduce((acc, path) => [...acc, ...path.split('/')], [])
      .filter(s => s)
      .join('/')
  )
