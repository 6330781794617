import logger from 'universal-logger'
import { styleable } from 'universal-logger-browser'
import config from './config'

const log = logger().use(styleable({ colorized: true }))

log.disableStackTrace()
log.setLevel(logger[config().LOG_LEVEL])

export default log
