import { createMuiTheme } from '@material-ui/core/styles'
import CHAM from './styles/colors'

// https://www.chameleondesignsystem.com/

const ONE_PIX_BORDER_GREY = `1px solid ${CHAM.NEUTRAL[100]}`

const buildSizeGradient = (names, steps) => {
  if (steps.length >= names.length) {
    throw new Error('Must have (names - 1) steps.')
  }
  return (base, map) =>
    names.reduce(
      (acc, name, idx) => ({
        ...acc,
        [name]: map(
          steps.reduce((size, inc, i) => (i < idx ? size + inc : size), base)
        )
      }),
      {}
    )
}

const pxMap = size => [size, 'px'].join('')

const fontSizeTargets = ['root', 'h6', 'h5', 'h4', 'h3', 'h2', 'h1']

const buildFontSizes = buildSizeGradient(
  fontSizeTargets,
  '0,0,2,6,8,10'.split(',').map(Number)
)

const buildFontLineHeights = buildSizeGradient(
  fontSizeTargets,
  '0,0,3,9,9,10'.split(',').map(Number)
)

export const DROPDOWN_ITEM_HEIGHT = 47.6

export const LIST_ITEM_HEIGHT = 65

export const paperListStyle = (pageSize = 5) => ({
  paperList: {
    maxHeight: Math.ceil(LIST_ITEM_HEIGHT * pageSize),
    overflowY: 'scroll'
  }
})

export const makeFormStyle = ({
  spacing,
  palette: {
    common: { grey }
  }
}) => ({
  form: {
    backgroundColor: grey.light,
    padding: spacing(1),
    minWidth: 300
  },
  containedForm: {
    minWidth: 300
  },
  formRow: {
    padding: spacing(1)
  },
  formInput: {
    width: '100%'
  },
  formInputDisabled: {
    width: '100%',
    opacity: 0.5
  }
})

export default ({ fontSize, fontLineHeight }) => {
  const fontSizes = buildFontSizes(fontSize, pxMap)
  const fontLineHeights = buildFontLineHeights(fontLineHeight, pxMap)
  return createMuiTheme({
    typography: {
      fontFamily: ['Lato'],
      useNextVariants: true
    },
    palette: {
      common: {
        white: {
          main: CHAM.WHITE,
          dark: '#FEFEFE'
        },
        grey: {
          light: CHAM.NEUTRAL[100],
          main: CHAM.NEUTRAL[500],
          dark: CHAM.NEUTRAL[600]
        },
        green: {
          light: CHAM.GREEN[100],
          main: CHAM.GREEN[500],
          dark: CHAM.GREEN[600]
        },
        red: {
          light: CHAM.RED[100],
          main: CHAM.RED[500],
          dark: CHAM.RED[600],
          contrastText: CHAM.WHITE
        },
        yellow: {
          light: CHAM.YELLOW[100],
          main: CHAM.YELLOW[500],
          dark: CHAM.YELLOW[600]
        }
      },
      primary: {
        light: CHAM.BLUE[100],
        main: CHAM.BLUE[500],
        dark: CHAM.BLUE[600],
        contrastText: CHAM.WHITE
      },
      secondary: {
        light: CHAM.PURPLE[100],
        main: CHAM.PURPLE[500],
        dark: CHAM.PURPLE[600],
        contrastText: CHAM.WHITE
      },
      error: {
        light: CHAM.RED[100],
        main: CHAM.RED[500],
        dark: CHAM.RED[600],
        contrastText: CHAM.WHITE
      }
    },
    overrides: {
      MuiTypography: {
        root: {
          fontSize: fontSizes.root,
          lineHeight: fontLineHeights.root
        },
        h1: {
          fontSize: fontSizes.h1,
          lineHeight: fontLineHeights.h1,
          fontWeight: 'bold'
        },
        h2: {
          fontSize: fontSizes.h2,
          lineHeight: fontLineHeights.h2,
          fontWeight: 'bold'
        },
        h3: {
          fontSize: fontSizes.h3,
          lineHeight: fontLineHeights.h3
        },
        h4: {
          fontSize: fontSizes.h4,
          lineHeight: fontLineHeights.h4,
          fontWeight: 'bold'
        },
        h5: {
          fontSize: fontSizes.h5,
          lineHeight: fontLineHeights.h5
        },
        h6: {
          fontSize: fontSizes.h6,
          lineHeight: fontLineHeights.h6
        },
        caption: {
          fontSize: '0.65rem',
          lineHeight: 1,
          color: CHAM.NEUTRAL[400]
        }
      },
      MuiPaper: {
        elevation4: {
          boxShadow: 'none'
        }
      },
      MuiLink: {
        root: {
          cursor: 'pointer',
          color: CHAM.BLUE[500],
          '&:hover': {
            color: CHAM.BLUE[600],
            textDecoration: 'none'
          },
          '&:disabled': {
            cursor: 'default',
            color: CHAM.BLUE[100]
          }
        }
      },
      MuiButton: {
        root: {
          padding: '10px 20px',
          marginRight: 5,
          fontWeight: 'bold',
          '&:disabled': {
            backgroundColor: CHAM.NEUTRAL[200] + '!important'
          },
          '& svg': {
            marginRight: 8
          },
          '& .MuiButton-startIcon': {
            marginRight: 0
          }
        },
        contained: {
          boxShadow: 'none'
        },
        containedPrimary: {
          backgroundColor: CHAM.BLUE[500]
        }
      },
      MuiAppBar: {
        root: {
          '& .MuiIconButton-root': {
            color: 'inherit'
          }
        }
      },
      MuiList: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0
        }
      },
      MuiListItemText: {
        root: {
          '&:hover >span': {
            color: 'inherit'
          }
        }
      },
      MuiSelect: {
        root: {
          minWidth: 120,
          display: 'flex',
          alignItems: 'center'
        }
      },
      MuiSnackbarContent: {
        root: {
          padding: '5px 10px'
        }
      },
      MuiTabs: {
        root: {
          marginTop: -10
        }
      },
      MuiTableHead: {
        root: {
          '& >tr:last-child': {
            border: ONE_PIX_BORDER_GREY,
            borderTop: 0,
            borderBottom: 0
          }
        }
      },
      MuiTableBody: {
        root: {
          border: ONE_PIX_BORDER_GREY
        }
      },
      MuiTableRow: {
        root: {
          height: 42
        },
        head: {
          height: 42
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: 0,
          borderTop: ONE_PIX_BORDER_GREY
        },
        head: {
          fontSize: 16
        }
      },
      MuiTableFooter: {
        root: {}
      },
      MuiTablePagination: {
        toolbar: {
          fontSize: 14,
          backgroundImage: 'none'
        },
        caption: {
          fontSize: 14
        }
      },
      MuiTooltip: {
        tooltip: {
          color: CHAM.WHITE,
          fontSize: '0.875rem'
        }
      }
    }
  })
}
