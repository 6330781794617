export default {
  local: {
    APP_URL: 'http://localhost:3000/',
    APP_URL_PREFIX: '',
    APP_BFF_URL: 'http://localhost:8765',
    PARTNER_UUID_KEY: 'lmi-pp-partner-uuid',
    USER_PARTNERS_KEY: 'lmi-pp-user-partners',
    ADMIN_PORTAL_PBX_BASE_URL: 'https://my.dev.jive.com/pbx',
    GOTOADMIN_BASE_URL: 'https://admin.dev.gtc.goto.com',
    PARTNER_EXCHANGE_BASE_URL: 'https://partnerexchange.logmeininc.com/',
    DEFAULT_PARTNER_ID: 'd06bbdc2-795f-45b7-b14e-a8b585f8fe38',
    DEFAULT_LANGUAGE: 'en-US',
    DEFAULT_REGION: 'US',
    SUPPORTED_REGIONS: ['america', 'europe', 'oceania'],
    INTERCOM_APP_ID: 'bbpd2pvl',
    IS_LOCAL_ENV: true,
    BETA: true
  },
  development: {
    APP_URL: 'https://my.dev.jive.com/partner/',
    APP_URL_PREFIX: '/partner',
    APP_BFF_URL: 'https://api.dev.jive.com/partner-admin',
    DEFAULT_PARTNER_ID: null,
    IS_LOCAL_ENV: false
  },
  developmentHvoice: {
    APP_URL: 'https://my.dev.hvoice.net/partner/',
    APP_URL_PREFIX: '/partner',
    APP_BFF_URL: 'https://api.dev.jive.com/partner-admin',
    DEFAULT_PARTNER_ID: null,
    IS_LOCAL_ENV: false
  },
  staging: {
    APP_URL: 'https://my.staging.jive.com/partner/',
    APP_URL_PREFIX: '/partner',
    APP_BFF_URL: 'https://api.staging.jive.com/partner-admin',
    ADMIN_PORTAL_PBX_BASE_URL: 'https://my.staging.jive.com/pbx',
    GOTOADMIN_BASE_URL: 'https://admin.staging.gtc.goto.com',
    DEFAULT_PARTNER_ID: null,
    IS_LOCAL_ENV: false
  },
  production: {
    APP_URL: 'https://my.jive.com/partner/',
    APP_URL_PREFIX: '/partner',
    APP_BFF_URL: 'https://api.jive.com/partner-admin',
    ADMIN_PORTAL_PBX_BASE_URL: 'https://my.jive.com/pbx',
    GOTOADMIN_BASE_URL: 'https://admin.goto.com',
    DEFAULT_PARTNER_ID: null,
    IS_LOCAL_ENV: false
  },
  hvoice: {
    APP_URL: 'https://my.hvoice.net/partner/',
    APP_URL_PREFIX: '/partner',
    APP_BFF_URL: 'https://api.jive.com/partner-admin',
    ADMIN_PORTAL_PBX_BASE_URL: 'https://my.hvoice.net/pbx',
    GOTOADMIN_BASE_URL: 'https://admin.goto.com',
    DEFAULT_PARTNER_ID: null,
    IS_LOCAL_ENV: false
  }
}
