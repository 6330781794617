export default {
  local: {
    AUTH_SERVICE_URL: 'http://localhost:3000/partner/authenticate',
    AUTH_CALLBACK_URL: 'http://localhost:3000/partner/authenticate/callback',

    AUTH_DATA_STORAGE_KEY: 'lmi-pp-token',
    AUTH_CLIENT_ID: 'portal.partner',
    AUTH_SCOPES: ['pbx.v1.admin', 'identity:'].join(' '),
    AUTH_LDAP: ['platform-admin'].join(','),

    AUTH_LOCAL_SIMULATE_ERRORS_FREQUENCY: 0.2,

    AUTH_LOGOUT_URL: 'https://authenticationed1.lmiinc.test.expertcity.com/oauth/logout',
    AUTH_LOGGED_IN_USER_LINK_URL: 'https://my.jive.com/me/',
    AUTH_TOCKEN_VALIDITY_TIMEOUT: 6000,
  },
  development: {
    AUTH_SERVICE_URL: 'https://authenticationed1.col.test.expertcity.com/oauth/authorize',
    AUTH_CALLBACK_URL: 'https://my.dev.jive.com/partner/authenticate/callback',
    AUTH_CLIENT_ID: '377b33ef-b4cb-44b7-80f3-112068c66c8f',
    AUTH_LOGOUT_URL: 'https://authenticationed1.lmiinc.test.expertcity.com/oauth/logout',
    AUTH_TOCKEN_VALIDITY_TIMEOUT: 3000,
  },
  developmentHvoice: {
    AUTH_SERVICE_URL: 'https://authenticationed1.col.test.expertcity.com/oauth/authorize',
    AUTH_CALLBACK_URL: 'https://my.dev.hvoice.net/partner/authenticate/callback',
    AUTH_CLIENT_ID: '377b33ef-b4cb-44b7-80f3-112068c66c8f',
    AUTH_LOGOUT_URL: 'https://authenticationed1.lmiinc.test.expertcity.com/oauth/logout',
    AUTH_TOCKEN_VALIDITY_TIMEOUT: 3000,
  },
  staging: {
    AUTH_SERVICE_URL: 'https://authenticationstage.logmeininc.com/oauth/authorize',
    AUTH_CALLBACK_URL: 'https://my.staging.jive.com/partner/authenticate/callback',
    AUTH_CLIENT_ID: '7f756a4b-b2d6-404d-a5c4-b20192202473',
    AUTH_LOGOUT_URL: 'https://authenticationstage.logmeininc.com/oauth/logout',
    AUTH_TOCKEN_VALIDITY_TIMEOUT: 3000,
  },
  production: {
    AUTH_SERVICE_URL: 'https://authentication.logmeininc.com/oauth/authorize',
    AUTH_CALLBACK_URL: 'https://my.jive.com/partner/authenticate/callback',
    AUTH_CLIENT_ID: '24b9b5f1-265c-403b-a3eb-0d4f707a5694',
    AUTH_LOGOUT_URL: 'https://authentication.logmeininc.com/oauth/logout',
    AUTH_TOCKEN_VALIDITY_TIMEOUT: 3000,
  },
  hvoice: {
    AUTH_SERVICE_URL: 'https://authentication.logmeininc.com/oauth/authorize',
    AUTH_CALLBACK_URL: 'https://my.hvoice.net/partner/authenticate/callback',
    AUTH_CLIENT_ID: 'c371c455-4300-4ccf-a360-330a36f01bba',
    AUTH_LOGOUT_URL: 'https://authentication.logmeininc.com/oauth/logout',
    AUTH_TOCKEN_VALIDITY_TIMEOUT: 3000,
  },
}
