import React from 'react'
import { FormattedMessage } from 'react-intl'
import List from '@material-ui/core/List'
import ComputerIcon from '@material-ui/icons/Computer'
import SignalIcon from '@material-ui/icons/SignalCellularConnectedNoInternet2Bar'
import UsersIcon from '@material-ui/icons/AccountCircle'
import UserGroupsIcon from '@material-ui/icons/SupervisedUserCircle'
import SecurityIcon from '@material-ui/icons/Security'
import SettingsIcon from '@material-ui/icons/Settings'
import OpenInNewWindowIcon from '@material-ui/icons/OpenInNew'
import LeftMenuItem from './LeftMenuItem'
import { useCurrentUser } from '../../UserContextProvider'
import makeStyles from '../../../utils/makeStyles'

const useStyles = makeStyles(() => ({
  testNetworkItem: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: '0.25em'
    }
  }
}))

export default () => {
  const classes = useStyles()
  const { permissions } = useCurrentUser()
  return (
    <List component='nav'>
      {permissions.listOrg && (
        <LeftMenuItem
          id='pbxsPage'
          href='/pbx/'
          text={<FormattedMessage id='organizations' defaultMessage='PBXs' />}
          icon={<ComputerIcon />}
        />
      )}
      <LeftMenuItem
        id='testNetworkPage'
        onClick={() =>
          window.open('https://jive.com/partners/use-jive-view/', '_blank')
        }
        text={
          <div className={classes.testNetworkItem}>
            <FormattedMessage id='testNetwork' defaultMessage='Test Network' />
            <OpenInNewWindowIcon fontSize='small' color='action' />
          </div>
        }
        tooltipText={
          <FormattedMessage
            id='testNetworkTooltip'
            defaultMessage='Test Network (new window)'
          />
        }
        icon={<SignalIcon />}
      />
      {permissions.listUser && (
        <LeftMenuItem
          id='usersPage'
          href='/users/'
          text={<FormattedMessage id='users' defaultMessage='Users' />}
          icon={<UsersIcon />}
        />
      )}
      {permissions.listGroup && (
        <LeftMenuItem
          id='userGroupsPage'
          href='/usergroups/'
          text={
            <FormattedMessage id='userGroups' defaultMessage='User Groups' />
          }
          icon={<UserGroupsIcon />}
        />
      )}
      {permissions.retrievePartner && (
        <LeftMenuItem
          id='securityPage'
          href='/security/'
          text={<FormattedMessage id='security' defaultMessage='Security' />}
          icon={<SecurityIcon />}
        />
      )}
      {permissions.retrievePartner && (
        <LeftMenuItem
          id='settingsPage'
          href='/settings/'
          text={<FormattedMessage id='settings' defaultMessage='Settings' />}
          icon={<SettingsIcon />}
        />
      )}
    </List>
  )
}
