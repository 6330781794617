import gql from 'graphql-tag'

export const CURRENT_USER = gql`
  query {
    result: currentUser {
      currentUser: entity {
        enabled,
        permissions {
          createOrg
          retrieveOrg
          listOrg
          updateOrg
          createUser
          retrieveUser
          listUser
          updateUser
          deleteUser
          createGroup
          retrieveGroup
          listGroup
          updateGroup
          deleteGroup
          retrievePartner
          updatePartner
          createDoc
          retrieveDoc
          listDoc
          updateDoc
          deleteDoc        
          isPlatformAdmin
        }
        groupPermissionsDisabled
      }
      exception {
        message
        code
      }
    }
  }
`
