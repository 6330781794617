import React, { createContext, useEffect } from 'react'
import ga from 'react-ga'
import { BrowserRouter } from 'react-router-dom'
import { useLocation } from 'react-router'
import { ApolloProvider } from '@apollo/react-hooks'
import Layout from './Layout'
import ErrorHandler from './ErrorHandler'
import Authenticator from './Authenticator'
import I18nProvider from './I18nProvider'
import ThemeProvider from './ThemeProvider'
import { apolloClient } from '../utils/graphql'
import * as auth from '../utils/auth'
import * as url from '../utils/url'
import config from '../utils/config'

export const AppContext = createContext()

export const LayoutWithHistoryListener = () => {
  const location = useLocation()
  useEffect(() => ga.pageview(location.pathname), [location])
  return <Layout />
}

export default () => {
  const partnerId = url.getUrlPartnerId()
  const authenticated = !!auth.getLocalAuthenticationData()
  const { error } = url.extractUrlQueryParameters()
  // if there is an error, let the ErrorHandle do it's job
  // if the user is not authenticated, let the Authenticator do it's job
  if (!error && authenticated) {
    // if there is no partner id:
    // * Let's check available user partners (or the default partner id in local)
    // * or let's redirect to the error page (no partner found)
    if (!partnerId) {
      setImmediate(async () => {
        const userPartners = await auth.retrieveUserPartners(authenticated)
        localStorage.setItem(
          config().USER_PARTNERS_KEY,
          JSON.stringify(userPartners)
        )
        const redirectUrl = url.checkUrlForRedirection(
          userPartners,
          config().DEFAULT_PARTNER_ID
        )
        if (redirectUrl) {
          url.redirect(redirectUrl)
        }
      })
    }
  }
  const basename = partnerId && authenticated ? `${config().APP_URL_PREFIX}/${partnerId}` : ''
  ga.set({
    env: config().ENV,
    partnerId: localStorage.getItem(config().PARTNER_UUID_KEY)
  })
  return (
    <I18nProvider>
      <ErrorHandler>
        <AppContext.Provider value={{ basename }}>
          <BrowserRouter basename={basename}>
            <ApolloProvider client={apolloClient}>
              <ThemeProvider sizeBase={16}>
                <Authenticator>
                  {authenticated && partnerId && <LayoutWithHistoryListener />}
                </Authenticator>
              </ThemeProvider>
            </ApolloProvider>
          </BrowserRouter>
        </AppContext.Provider>
      </ErrorHandler>
    </I18nProvider>
  )
}
