const CONFIG_TYPES = ['app', 'logger', 'auth', 'ga', 'feature']

const configs = CONFIG_TYPES.reduce(
  (acc, type) => ({
    ...acc,
    [type]: require(`../configs/${type}`).default
  }),
  {}
)

const isFeatureBranch = () => {
  const host = window.location.hostname.toLowerCase()
  return host.startsWith('feature')
}

export const getEnvironment = () => {
  if (isFeatureBranch()) {
    return 'feature'
  }
  return Object.keys(configs.app).find(
    env =>
      new URL(configs.app[env].APP_URL).hostname === window.location.hostname
  )
}

const loadConfig = ({ env, type }) => {
  const config = {}
  try {
    Object.assign(config, configs[type].local)
    if (env !== 'local') {
      Object.assign(config, configs[type][env])
    }
  } finally {
    // nothing to do here
  }
  return config
}

let config = null

export const build = () => {
  if (!config) {
    const env = getEnvironment()
    config = CONFIG_TYPES.reduce(
      (acc, type) => ({ ...acc, ...loadConfig({ env, type }) }),
      { ENV: env }
    )
  }
  return {
    ...config,
    GA_TEST_MODE: navigator.userAgent === 'chromium-e2e'
  }
}

export default build
