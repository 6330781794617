import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Menu from '@material-ui/core/Menu'
import { useQuery } from '@apollo/react-hooks'
import LocaleMenuItem from './LocaleMenuItem'
import { Button } from '../Button'
import makeStyles from '../../../utils/makeStyles'
import { LocaleContext } from '../../I18nProvider'
import Loader from '../Loader'
import { GET_LANGS_QUERY } from '../../../queries/others'
import logger from '../../../utils/logger'
import config from '../../../utils/config'
import { CHAM_BLUE } from '../../../utils/styles/colors'

const useStyles = makeStyles(() => ({
  localeButton: {
    fontWeight: 'inherit',
    color: 'inherit',
    border: '1px solid',
    borderRadius: 8,
    lineHeight: 1,
    minWidth: 0,
    width: '5em',
    height: '2.5em',
    padding: 0,
    '&:focus': {
      backgroundColor: CHAM_BLUE[600]
    }
  }
}))

const getLanguageCode = locale => locale.substr(0, 2)

const languageLabelGenerator = currentLocale => ({
  name,
  code,
  localName,
  region
}) => {
  const parts = [name]
  if (getLanguageCode(currentLocale) !== getLanguageCode(code)) {
    parts.push(`- ${localName}`)
  }
  return [...parts, `(${region})`].join(' ')
}

export const LocalesMenu = ({ setLocale, languages }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [anchor, setAnchor] = useState()
  const onClose = () => setAnchor()
  const onSelect = locale => {
    onClose()
    if (locale === intl.locale) {
      return
    }
    localStorage.setItem('locale', locale)
    setLocale(locale)
  }
  const getLanguageLabel = languageLabelGenerator(intl.locale)
  return (
    <>
      <Button
        id='languageOpenMenuBtn'
        className={classes.localeButton}
        onClick={({ target }) => setAnchor(target)}
        analytics={{
          category: 'Navigation',
          label: 'Open language selection dropdown'
        }}
      >
        {intl.locale}
      </Button>
      <Menu anchorEl={anchor} open={!!anchor} onClose={onClose}>
        {languages.map(language => {
          const id = `lang-${language.code}`
          return (
            <LocaleMenuItem
              id={id}
              key={id}
              label={getLanguageLabel(language)}
              locale={language.code}
              onSelect={onSelect}
            />
          )
        })}
      </Menu>
    </>
  )
}

export default props => {
  const intl = useIntl()
  const currentLocale = intl.locale || config().DEFAULT_LANGUAGE
  const { setLocale } = React.useContext(LocaleContext)
  const { loading, error, data } = useQuery(GET_LANGS_QUERY, {
    variables: { currentLocale }
  })
  if (loading) {
    return <Loader size={20} thickness={2} />
  }
  if (error) {
    logger.warn('Locales Query error:', error)
    return null
  }
  return (
    <LocalesMenu setLocale={setLocale} languages={data.languages} {...props} />
  )
}
