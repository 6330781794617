import React, { forwardRef } from 'react'
import ga from 'react-ga'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router'
import MuiListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import Hidden from '@material-ui/core/Hidden'

const TOOLTIP_DELAY_MS = 2000

export const LeftMenuItem = forwardRef(
  ({ id, href, onClick, children, disabled = false, ...props }, ref) => {
    const history = useHistory()
    const location = useLocation()
    const selected = href === location.pathname
    const onClickWithTracking = target => {
      if (selected) {
        return
      }
      ga.event({
        category: 'Navigation',
        action: 'Click',
        label: `Left menu ${href}`
      })
      onClick ? onClick(target) : history.push(href)
    }
    return (
      <MuiListItem
        id={id}
        ref={ref}
        onClick={onClickWithTracking}
        selected={selected}
        disabled={disabled}
        button
        {...props}
      >
        {children}
      </MuiListItem>
    )
  }
)

LeftMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool
}

export default ({
  id,
  icon,
  text,
  href,
  onClick,
  tooltipText,
  disabled = false
}) => {
  const item = (
    <LeftMenuItem id={id} href={href} onClick={onClick} disabled={disabled}>
      <ListItemIcon>{icon}</ListItemIcon>
      <Hidden mdDown>
        <ListItemText>{text}</ListItemText>
      </Hidden>
    </LeftMenuItem>
  )
  return (
    <>
      <Hidden mdDown>{item}</Hidden>
      <Hidden lgUp>
        <Tooltip
          title={tooltipText || text}
          placement='right'
          enterDelay={TOOLTIP_DELAY_MS}
        >
          {item}
        </Tooltip>
      </Hidden>
    </>
  )
}
