import React, { createContext, useEffect } from 'react'
import ga from 'react-ga'
import config from '../utils/config'
import logger from '../utils/logger'
import * as auth from '../utils/auth'
import * as url from '../utils/url'

export const AuthContext = createContext()

const login = () => {
  const { location } = window
  if (config().IS_LOCAL_ENV) {
    // local fake mechanism
    const redirectUrl = auth.getLocalLoginRedirectUrl(location)
    if (redirectUrl) {
      url.redirect(redirectUrl)
      return
    }
  }
  if (auth.isSuccessCallback(location)) {
    // after a successfull login, we want to redirect to the
    // original url we have before the authentication
    const { state: inflightRequest } = url.extractUrlParameters({
      sources: [location.hash]
    })
    url.redirect(inflightRequest || config().APP_URL)
    return
  }
  const redirectUrl = auth.getAuthRequestUrl({
    inflightRequest: location.toString()
  })
  logger.debug('Authentication request redirection:', redirectUrl)
  ga.event({
    category: 'Authentication',
    action: 'Redirect',
    label: `Auth redirection to ${redirectUrl}`
  })
  url.redirect(redirectUrl)
}

const logout = () => {
  auth.logout()
  url.redirect(`${config().AUTH_LOGOUT_URL}?client_id=${config().AUTH_CLIENT_ID}&redirect_uri=${encodeURIComponent(config().AUTH_CALLBACK_URL)}`)
}

export default ({ children }) => {
  const authData = auth.getLocalAuthenticationData()
  if (!authData) {
    login()
    return null
  }
  useEffect(() => {
    const ctx = setInterval(() => {
      const authData = auth.getLocalAuthenticationData()
      if (!authData || auth.isExpired(authData)) {
        ga.event({
          category: 'Authentication',
          action: 'Refresh session',
          label: !authData ? 'No session found' : 'Session expired'
        })
        auth.logout()
        login()
      }
    }, config().AUTH_TOCKEN_VALIDITY_TIMEOUT)
    return () => clearInterval(ctx)
  })
  return (
    <AuthContext.Provider value={{ logout }}>{children}</AuthContext.Provider>
  )
}
